.clientListName {
    display: block;
    margin-top: 25px;
    text-align: center;
    text-decoration: none;
    color: #0E90CD;
}
.clientListName:hover {
    color: black;
    text-decoration: none;
}
.clientListName:active {
    text-decoration: none;
}