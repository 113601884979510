.submitButton {
  text-align: center;
  display: block;
  border-radius: 5px;
  padding: 10px;
  max-width: 475px;
  width: 100%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.feedbackInput {
  font-size: 20px;
}
.feedbackSelect {
  font-size: 20px;
  max-width: 475px;
}
