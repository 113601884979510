.toggleView {
    margin: auto;
    width: 90%;
    margin-bottom: 20px;  
}
.toggleButton {
    width: 50%;
    display: inline-block;
    border-radius: 0;
}
.divisionLine {
    border-color: black;
}

.snoreScrollMenu {
    overflow-y: hidden;
    overflow-x: scroll;
    margin:auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content:space-evenly
}
.snoreDataContainer {
    margin: 8px;
    text-align: center;
}
.snoreMiddleBar {
    margin-bottom: 10px;
    display: block;
}
.snoreUpperText {
    display: inline-block;
    font-size: 8px;
    white-space: nowrap;
}
.snoreLowerText {
    margin-bottom: 10px;
    display: inline-block;
    font-size: 8px;
    white-space: nowrap;
}

.snoreDataButton {
    padding: 12px;
    width: 12px;
    border-radius: 30px;
    position:relative;
    border: none;
    display:inline;
    text-align: center;
}
.snoreDataButton:hover {
    cursor: pointer;
}



  

  
