.feedbackButton {
    text-align: center;
    display: block;
    border-radius: 5px;
    padding: 10px;
    max-width: 475px;
    width: 100%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    text-decoration: none;
}
Link {
   text-decoration: none;
}