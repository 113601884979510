.formStyle {
  max-width: 450px;
  width: 100%;
  margin: auto; 
  align-items:center

}
#inputStyle {
  display: block;
  width: 100%;
  margin: auto;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  border-radius: 3px;
  border: 1px solid #717071;
}
input.error {
  border-color: red;
}
.inputFeedback {
  color: red;
  margin: auto;
  margin-bottom: 15px;
  line-height: 10px;
}
.buttonStyle {
  max-width: 450px;
  width: 100%;
}
.reminderStyle {
  margin: auto;
  text-align: center;
  margin-top: 30px;

}
.switchStyle {
  display: inline-block;
  margin: auto;
  text-align: center;
  margin-left: 10px;
}
.switchStyle:hover {
  color: #0E90CD
}
.container {
  text-align: center;
  margin: auto;
  height: 100vh;

}


.align {
  text-align: center;
}
