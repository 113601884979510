.dayListDataContainer {
    width: 100%;
    display: flex;
    margin: auto;
    align-items: center;
    justify-content:space-evenly ;
    margin-bottom: 10px;
    margin-top: 10px;
}
.dayListDataBtn {
    font-size: 18px;
    border-radius: 30px;
    padding: 5px;
    background-color:#0E90CD
}
.dayListDataBtn:hover {
    background-color:#0E90CD;
    color: white;
}
.dayListName {
    display: block;
    text-align: center;
    color: #0E90CD;
}
.dayListName:hover {
    color: black
}