body {
  font-family:sans-serif;
}
/*Title*/
h1 {
  display: block;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 50px;
  font-size: 7vw;
}
@media screen and (min-width: 1000px) {
  h1 {
    font-size: 80px;
  }
}
/*SubTitle*/
h2 {
  font-size: 5vw, 10vh;
  text-decoration: none;
}
/*DataText*/
h3 {
  font-size: 20px;
  margin: 0 auto;
}
/*ListDetailSleepDataText*/
h4 {
  font-size: 10px;
  text-align: center;
  color: black;
  display: inline-flex;
  margin-left: 5%;
  text-align: center;
}
/*DayListSleepDataText*/
h5 {
  font-size: 15px;
  text-transform: capitalize;
}
/*All pages detail*/
.detailInfo {
  text-align: center;
  display: block;
  border: 1px solid #717071;
  border-radius: 5px;
  padding: 10px;
  max-width: 450px;
  width: 100%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.noDataMessage {
  text-align: center;
  font-size: 20px;
}
.pageButton {
  margin: auto;
  margin-top: 20px;
  margin-left: 20px;
  max-width: 100px;
  width: 100%;
}
.pageButton:hover {
  cursor: pointer;
}
button {
  background-color: #0E90CD;
  color: white;
  border: 1px solid #0E90CD;
  background-color: 250ms;
  border-radius: 10px;
  margin: auto;
  padding: 10px 15px;
}
button:hover {
  cursor: default;
  background-color: white;
  color: #0E90CD;
}



