.divisionLine {
    border-color: black;
}
.scrollMenu {
    overflow-y: hidden;
    overflow-x: scroll;
    margin:auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content:space-between;
}

.sleepDataContainer {
    margin: 8px;
    text-align: center;
}

.middleBar {
    margin-bottom: 10px;
    display: block;
}
.upperText {
    display: inline-block;
    font-size: 8px;
    white-space: nowrap;
}
.lowerText {
    margin-bottom: 10px;
    display: inline-block;
    font-size: 8px;
    white-space: nowrap;
    align-self: center;

}
.lowerDateText {
    display: inline-block;
    font-size: 8px;
    white-space: nowrap;  
}
.sleepDataButton {
    padding: 12px;
    width: 12px;
    border-radius: 30px;
    position:relative;
    border: none;
    display:inline;
    text-align: center;
    
}
.sleepDataButton:hover {
    cursor: pointer;
}

.snoringData {
    background-color: white;
    position: absolute; 
    z-index: 1;
    width: 24px;
    margin-left: -12px;
}


  

  