.homeTitle {
    padding-top: 40px;
}
.alignButton {
    text-align: center;
    width: 100%;
}
.homeButton {
    display: inline-block;
    border-radius: 30px;
    width: 50%;
    max-width: 100%;
    margin: 0 auto;
    margin-top: 50px;
    margin-right: 10px;
    text-align: center;
}
.homeButton:hover {
    cursor: pointer;
}